import React, {useContext, useEffect, useState} from "react";
import {rgba} from "polished";
import {Container, Row, Col} from "react-bootstrap";
import {Section, Box, Text, Title, Button} from "../../components/Core";
import styled from "styled-components";
import {thousands_separators} from "../../utils/helperFn";
import GlobalContext from "../../context/GlobalContext";
import CheckAvailability from "../../sections/index/CheckAvailability";
import ukmap from "../../assets/image/svg/ukmap-main.svg";
import fttp from "../../assets/image/svg/fttp.svg";
import {graphql, navigate, useStaticQuery} from "gatsby";
import trustPilotImg from "../../assets/image/png/yf__box_new.png"
import rowBgImg from "../../assets/image/png/yf__pozadina.png"
import Cities from "../../assets/map/uk-regions/Cities";
import {SVGMap,RadioSVGMap} from "react-svg-map";
import UKRegions from "../../assets/map/uk-regions/uk-regions";
import Link from "gatsby-link";
import number1 from '../../assets/image/rebrand/number-1.svg';
import number2 from '../../assets/image/rebrand/number-2.svg';
import number3 from '../../assets/image/rebrand/number-3.svg';
import townBenefits from '../../assets/image/rebrand/town-benefits.jpg';

import connectivityIcon from '../../assets/image/rebrand/icon-connectivity.svg';
import investmentIcon from '../../assets/image/rebrand/icon-investment.svg';

const rowBgImgStyle = {
	backgroundImage: `url(${rowBgImg})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
};



const WhiteBox = styled.div`
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
    padding: 50px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
        padding: 20px;
    }
`;

const BenefitsSection = styled(Section)`
    background: url(${townBenefits}) no-repeat;
    background-size: cover;
`

const GraySection = styled(Section)`
    background-color: #eff0f3;
`

const renderStatusTitle = (Status) => {
    switch (Status) {
        case "live":
            return "Live"
        case "in_progress":
            return "In Build"
        case "planned":
            return "Plan"
        default:
            return "Plan"
    }
}

const FeatureTitle = styled(Text)`
    font-size: 1.3em;
    line-height: 1.2;
    color:black;
    font-weight: bold;
    display: block;
`;

const renderStatusIconColor = (Status) => {
    if (Status && Status === 'live') {
        return "success"
    } else if (Status && Status === 'in_progress') {
        return "info"
    } else {
        return "primary"
    }
}

const renderStatusIcon = (Status) => {
    if (Status && Status === 'live') {
        return "fa fa-check"
    } else if (Status && Status === 'in_progress') {
        return "fa fa-clock"
    } else if (Status && Status === 'planned') {
        return "fa fa-drafting-compass"
    } else {
        return "fa fa-drafting-compass"
    }
}

const renderStatusText = (Status, CityName) => {
    if (Status && Status === 'live') {
        return "FTTP network is now live!"
    } else if (Status && Status === 'in_progress') {
        return "We are building our FTTP network in your area"
    } else if (Status && Status === 'planned') {
        return "We are planning our FTTP network in your area"
    } else {
        return "We are planning our FTTP network in your area"
    }
}

const renderCheckAvailability = (Status) => {
    if (Status && (Status === 'live' || Status === 'in_progress')) {
        return (
            <Col sm={12} md={9} className="mt-4 mx-auto">
                <Container>
                    <Row className="justify-content-center text-center">
                        <CheckAvailability
                            hideLogo={true}
                            customTitle={""}
                            isFocused={false}
                        />
                    </Row>
                </Container>
            </Col>
        )
    }
}

const LocalStats = ({Size, Investment, Status, CityName, All}) => {
    const data = useStaticQuery(graphql`
       query getAvailableRegionsLSQuery {
        allStrapiRegions(filter: {towns: {elemMatch: {id: {gt: 0}}}}) {
            edges {
              node {
                  id
                  Slug
                  Name
              }
            }
          }
        }
    `)
    const netomniaRegions = data?.allStrapiRegions?.edges?.map(strapiRegion => strapiRegion.node);
    const AllCities = All?.strapiRegions?.towns
    const City = All?.strapiTowns
    const allUkRegions = UKRegions?.mapData?.map(region => ({
        name: region.name,
        id: region.id,
        path: region.path,
    }))
    const map = {
        "label": "Map of UK Regions",
        "viewBox": "-679.3 146.4 327.7 370.6",
        "locations": allUkRegions
    }
    const getLocationClassName = (location) => {
        console.log('getLocationClassName', location);
        return netomniaRegions.find(x => x.Slug === 'wales') ? 'svgregion active' : 'svgregion disabled'
    }
    const handleMapClick = (event) => {
        console.log('handleMapClick event', event);
    };

    const renderPreviousNextCityNav = () => {
        let prevCity
        let nextCity
        /* eslint-disable no-unused-expressions */
        AllCities?.find((city, index) => {
            if (city.Slug === City.Slug) {
                if (index === 0) {
                    prevCity = AllCities?.length - 1
                    nextCity = index + 1
                }
                else if (index === AllCities?.length - 1) {
                    nextCity = 0
                    prevCity = index - 1
                }
                else {
                    nextCity = index + 1
                    prevCity = index - 1
                }
            }
        }) 
        /* eslint-disable no-unused-expressions */

        return (
            <Row>
                <Col xs={6} className="text-left pl-sm-0">
                    <Link to={`/cities/${AllCities && AllCities[prevCity].Slug}`} className={AllCities?.length === 1 ? 'disabled black-link' : 'black-link'}>
                        <i className="fa fa-chevron-left mr-2"/>Previous City
                    </Link>
                </Col>
                <Col xs={6} className="text-right pr-sm-0">
                    <Link to={`/cities/${AllCities && AllCities[nextCity].Slug}`} className={AllCities?.length === 1 ? 'disabled black-link' : 'black-link'}>
                        Next City<i className="fa fa-chevron-right ml-2" />
                    </Link>
                </Col>
            </Row>
        )
    }

    const buttonBgImgStyle = {
        cursor: 'pointer',
        background: '#00ff85',
        padding: '0.85rem 1.75rem',
        minWidth: '200px',
        borderRadius: '6px',
        fontSize: '21px',
        fontWeight: '600',
        color: 'black',
        transition: 'all 0.5s'
    };

    useEffect(()=>{
        const element = document.getElementById(All?.strapiTowns?.Slug);
        if (element) {
            element.style.fill = 'red';
            element.style.r = '5';
            element.stroke = 'black'
        }
    })
    return(
        <>
            <GraySection>
                <Container>
                    <Row className="align-items-center mb-5 pb-5">
                        <Col lg={6} className="mt-3 mt-lg-4 interactive-map-wrapper">
                            <SVGMap
                                map={map}
                                onChange={(e) => handleMapClick(e.id)}
                                selectedLocationId={All?.strapiTowns?.region?.Slug}
                                locationClassName={getLocationClassName}
                                childrenAfter={<Cities filterData={All?.strapiTowns?.region?.Slug}/>}
                            />
                        </Col>
                        <Col lg={6} className="text-left">
                            <Title>{CityName} Full Fibre Network</Title>

                            <div className="flex mb-3">
                                <img src={connectivityIcon} alt="" className="mr-3" width={80}/>
                                <div className="align-self-center">
                                    <FeatureTitle>Connectivity</FeatureTitle>
                                    <Text>
                                        {`Improving connectivity for over ${thousands_separators(Size)} homes and businesses`}
                                    </Text>
                                </div>
                            </div>

                            <div className="flex mb-3">
                                <img src={investmentIcon} alt="" className="mr-3" width={80}/>
                                <div className="align-self-center">
                                    <FeatureTitle>Investment</FeatureTitle>
                                    <Text>{`Up to £${thousands_separators(Investment)} will be invested to deliver the infrastructure`}</Text>
                                </div>
                            </div>

                            <div className="flex mb-5">
                                <i className={`fa fa-3x ${renderStatusIcon(Status)} text-${renderStatusIconColor(Status)} fa-status-icon mr-3`}/>
                                <div className="align-self-center">
                                    <FeatureTitle>{renderStatusTitle(Status)}</FeatureTitle>
                                    <Text>{renderStatusText(Status, CityName)}</Text>
                                </div>
                            </div>

                            <Button
                                style={{
                                    background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                    border: 'none',
                                    width: 'auto'
                                }}
                                className="btn-red hvr-bounce-to-left mt-3"
                                onClick={() => navigate('/check-availability')}
                            >
                                Check Availability
                            </Button>

                        </Col>
                    </Row>
                    {renderPreviousNextCityNav()}
                    <Row className="mt-2 mb-5">
                        <hr />
                    </Row>
                    <Row>
                        <Col lg={6} className="mt-5">
                            <WhiteBox>
                                <Title variant="news">What is Full Fibre Broadband</Title>
                                <img alt="" src={fttp} className="mb-3"/>
                                <Text>
                                    Full fibre broadband is a new type of broadband that is made of entirely fibre optic cables.<br/>
                                    Currently, a lot of the broadband networks across the UK are made of both fibre optic cables
                                    AND copper cables. This means you aren’t receiving the fastest possible speeds!
                                </Text>
                            </WhiteBox>
                        </Col>
                        <Col lg="6" className="pl-lg-5 mt-5">
                            <Title variant="news">We're an FTTP Network Builder</Title>
                            <Text>At Netomnia, we are an independent full fibre network builder. This
                                means that we put the fibre optic cables in the ground and our
                                partner ISP will provide you with your package.
                            </Text><br/>
                            <Text>Being separate from other networks (such as Openreach and Virgin)
                                means that we are able to provide you with even more choice when it
                                comes to your broadband.
                            </Text><br/>
                            <Text>You don’t have to be served by the same, slow, unresponsive
                                broadband provider when your contract ends anymore.
                            </Text>
                        </Col>
                    </Row>
                </Container>
            </GraySection>
            <Section style={rowBgImgStyle}>
                <Container>
                    <Row>
                        <Col lg="6">
                            <Title color="white">
                                Join our network through our ISP Partners
                            </Title>
                            <Text color="white">Because we are only the full fibre network builder (and not the internet service
                                provider), it means that we are unable to sell you broadband.
                            </Text><br/>
                            <Text color="white">This means that if you are looking for a new broadband package over our network, you
                                must go through our ISP partners.
                            </Text><br/>
                            <Text color="white">
                                YouFibre offer incredible packages for low-costs. Their customer service is responsive
                                and able to help you through any situation. Don’t believe us? Their 4.9 Trustpilot score
                                speaks for itself.
                            </Text><br/>
                        </Col>
                        <Col lg="6" className="text-center">
                            <img alt="" src={trustPilotImg} className="img-fluid"/>
                            <Text color="white" className="mb-3 text-center mt-3"><b>Visit YouFibre here.</b></Text>
                            <a
                                href="https://youfibre.com/"
                                style={buttonBgImgStyle}
                            >
                                View Packages and Prices
                            </a>
                        </Col>
                    </Row>
            </Container>
        </Section>
        <BenefitsSection>
            <Container>
                <Row>
                    <Col lg={6}>
                        <WhiteBox>
                            <Title variant="news" color="#db118b">
                                Benefits of Full Fibre Broadband
                            </Title>
                            <Text className="mt-1 align-start">
                                <img src={number1} width={35}/><span className="ml-2">Faster speeds – receive internet speeds that are lightning fast and that can handle all of the devices in your home, even in the peak hours.</span>
                            </Text>
                            <Text className="mt-1 align-start">
                                <img src={number2} width={35}/><span className="ml-2">Ultra-reliable - you won’t have to worry about seeing a flashing red light ever again.</span>
                            </Text>
                            <Text className="mt-1 align-start">
                                <img src={number3} width={35}/><span className="ml-2">Clearer video calls – whether you work from home, or just keep in touch with loved ones often, you can have crystal clear video and audio calls.</span>
                            </Text>
                            <Button
                                style={{
                                    background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                    border: 'none',
                                    width: 'auto'
                                }}
                                className="btn-red hvr-bounce-to-left mt-4"
                                onClick={() => navigate('/check-availability')}
                            >
                                Check Availability
                            </Button>
                        </WhiteBox>
                    </Col>
                </Row>
            </Container>
        </BenefitsSection>
    </>
    )
}

export default LocalStats;
